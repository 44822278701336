import React, { Component } from "react"
import Accordion from '../../Accordion';
import { Image } from '../../Image';
import RenderContent from '../../RenderContent'
import { getUrlVars, isClient, compileQuery, slugify } from '../../../utils/helpers'
import { navigate } from "gatsby"
import './Gallery.scss';
import { FaTimes, FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

class ThisGallery extends Component {
  state = {
    filter: 'all',
    openNavKey: null
  };

  nivigateTo(event, value) {
    this.setState({
      filter: value
    })
  }

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, openNavKey) => {
    event.preventDefault();
    console.log('openNavKey:',openNavKey)
    document.body.classList.remove('modal-open')
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    openNavKey && document.body.classList.add('modal-open')
    return this.setState({ openNavKey });
  }

  render () {
    const {showFilter, categories, wrap, thumbView} = this.props
    const { filter, openNavKey } = this.state
    const pageAttr = getUrlVars()
    var filterPass = filter
    return (
      <section className="gallery">
        {/* {showFilter &&
          <div className="gallery-navigation">
           <div className="inner">
              <strong>Filter by: </strong>
              <ul>
              <li><button className={'all' === filter ? 'active' : ''} onClick={e => this.nivigateTo(e, 'all')}>All</button></li>
              {categories && categories.map(( category,index ) => (
                <li key={index}><button className={slugify(category.category) === filter ? 'active' : ''} onClick={e => this.nivigateTo(e, slugify(category.category))}>{category.category}</button></li>
              )
              )}
              </ul>
            </div>
          </div>
        } */}
        {/* <div className={wrap ? `wrap large` : `wrap`}>
          <div className="group">
            <div className="category">
              <div className="list">
                {categories && categories.map(( category,index ) => {
                  filterPass = filter === 'all' ? slugify(category.category) : filter
                  return filterPass === slugify(category.category) ? category.categoryItems.map((item, itemIndex) => (
                    <div className="item" key={itemIndex}>
                        <button onClick={event => this.toggleModal(event, `${index}-${itemIndex}`)}>
                          <div className={`gallery-modal${openNavKey === `${index}-${itemIndex}` ? ' open' : ' closed'}${thumbView ? ' thumb' : ''}`}>
                            <div className="inner">
                              <div className="wrap">
                                <div className="title">{item.title}</div>
                                <Image className="image" src={item.url} />
                                <FaTimes className="close"/>
                              </div>
                            </div>
                          </div>
                        </button>
                        <div className={`controls${openNavKey === `${index}-${itemIndex}` ? ' open' : ' closed'}`}>
                          {category.categoryItems.length > itemIndex + 1 && <button className="next" onClick={event => this.toggleModal(event, `${index}-${itemIndex + 1}`)}><FaChevronRight/></button> }
                          {itemIndex > 0 && <button className="back" onClick={event => this.toggleModal(event, `${index}-${itemIndex - 1}`)}><FaChevronLeft/></button>}
                        </div>
                    </div>
                    )
                  )
                  : null
                }
                )}
              </div>
            </div>
          </div>
        </div> */}
      </section>
    );
  }
};

export const Gallery = (props) => {
  return (
    <ThisGallery {...props} />
  )
}
