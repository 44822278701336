import React, { Component, useEffect, useState } from 'react'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel'
import { Breadcrumbs } from '../../Breadcrumbs'
import { decodeEntities, useWindowSize } from '../../../utils/helpers'
import './Header.scss'
import './sharelink.scss'

class HeaderSlider extends Component {
  
  render() {
    const { slider, globalTitle, location, breadcrumbs, centre } = this.props
    const settings = {
      container: 'page-carousel',
      nav: true,
      mouseDrag: true,
      items: 1,
      controls: true,
      rewind: true,
      loop: false,
      mode: 'gallery',
      autoplay: globalTitle ? true : false,
    }
    
    return (
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slide_count) => (
            <div key={slide_count} className="slide">
              {!globalTitle && (
                <div className={`title-block${centre ? ' centre' : ''}`}>
                  <div className="wrap">
                    <div className="inner">
                      {breadcrumbs && <Breadcrumbs location={location} />}
                      {slide_count == 0 ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <h2
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      {slide.action && (
                        <GatsbyLink
                          to={slide.action.url}
                          className="action"
                          decode={true}
                        >
                          {slide.action.title}
                        </GatsbyLink>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Image src={slide.background} className="background" />
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const Header = props => {

  const {
    compact,
    globalTitle,
    breadcrumbs,
    header,
    subHeader,
    centre,
    location,
    showAsxData,
    asxIframeUrl,
    asxIframeUrlMobile,
  } = props
  
  const [isHome, setIsHome] = useState(false);
  
  useEffect(() => {
    if (window) {
      if (window.location.pathname === "/") {
        setIsHome(true);
      }
    }
  });
  
  return (
    <section className={`
      page-header${compact ? ' compact' : ''}
      ${isHome && showAsxData ? 'homepage-header' : ''}
    `}>
      <HeaderSlider {...props} />
      {globalTitle && (
        <div className={`title-block${centre ? ' centre' : ''}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
              />
              {subHeader && (
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {/* You must provide a mobile URL and desktop URL, otherwise just desktop URL will be used */}  
      {showAsxData && asxIframeUrl && <AsxData url={asxIframeUrl} mobileUrl={asxIframeUrlMobile ? asxIframeUrlMobile : asxIframeUrl} />}
    </section>
  )
}

function AsxData(props) {
  if (props) {
    const { url, mobileUrl } = props
    let activeUrl;
    const size = useWindowSize();
    size.width <= 501 ? activeUrl = mobileUrl : activeUrl = url;
    return (
      <div className="asx-iframe-wrapper">
        <iframe
          width="100%"
          frameBorder="0"
          scrolling="no"
          loading="lazy"
          style={{
            width: '1px',
            minWidth: '100%',
          }}
          className="asx-iframe__stockprice"
          src={activeUrl}
        />
      </div>
    )
  } else return null
}
