import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from './RelatedPass'

export const RelatedProjects = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          },
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          },
          allWordpressWpReports(
            sort: { fields: date, order: ASC }
            limit: 6
          ) {
            edges {
              node {
                ...ReportListFields
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} data={data} />}
    />
  );
}
