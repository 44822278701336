import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink'
import './SideNavigation.scss'

export const SideNavigationPass = props => {
  const { pageContext, data } = props
  let { wordpress_id, wordpress_parent } = pageContext;
  
  // if (!pageContext) {
  //   // it must be in preview mode then
  //   if (window.location.search) {
  //     let urlString = window.location.search;
  //     let params = new URLSearchParams(urlString)
  //     return wordpress_id = params.get('id');
  //   } 
  // }

  const pages = data.allWordpressPage.edges
  const currentPage = pages.filter(i => i.node.id === wordpress_id);
  const relatedPages = pages.filter(i => i.node.wordpress_parent === wordpress_parent)
  const childPages = pages.filter(i => i.node.wordpress_parent === wordpress_id)
  const parentPage = pages.filter(i => i.node.id === wordpress_parent)
  const loopPages = wordpress_parent === 0 ? childPages : relatedPages

  return loopPages &&
    <ul className="side-navigation">
      {currentPage[0].node.wordpress_parent === 0 ? 
      <li>
        <GatsbyLink to={currentPage[0].node.link} decode={true}>
        {currentPage[0].node.title}</GatsbyLink>
      </li>
      : parentPage[0].node.wordpress_parent === 0 && 
        <li>
          <GatsbyLink to={parentPage[0].node.link} decode={true}>
          {parentPage[0].node.title}</GatsbyLink>
        </li>
      }
      {loopPages && loopPages.map((page, index) =>
        <li key={index}><GatsbyLink to={page.node.link} decode={true}>{page.node.title}</GatsbyLink></li>
      )}
    </ul>  
}

export const SideNavigation = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPage(sort: {order: ASC, fields: menu_order}) {
            edges {
              node {
                id: wordpress_id
                title
                slug
                link
                menu_order
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <SideNavigationPass {...props} data={data} />}
    />
  );
}
